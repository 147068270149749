import React, { useState, useEffect, useRef } from 'react';
import {
	RxBell,
	RxEnvelopeClosed,
	RxTriangleDown,
	RxExit,
} from 'react-icons/rx';
import { HiOutlineKey } from 'react-icons/hi2';
import { avatar } from '../../assets/images';

const DashboardHeader = () => {
	const [dropdown, setDropdown] = useState(false);

	const dropdownRef = useRef();

	useEffect(() => {
		const handleDropdownOutsideClick = (event) => {
			if (dropdownRef && !dropdownRef.current.contains(event.target)) {
				setDropdown(false);
			}
		};

		//Add click event listener
		document.addEventListener('click', handleDropdownOutsideClick);

		//Remove click event listener
		return () => {
			document.removeEventListener('click', handleDropdownOutsideClick);
		};
	}, []);

	return (
		<div className="dashboard-header">
			<div className="dashboard-header-left">DasangBlogs</div>
			<div className="dashboard-header-left-sm">Dashboard</div>
			<div className="dashboard-header-right">
				<div className="dashboard-header-right-message">
					<RxEnvelopeClosed className="dashboard-header-right-icon" />
				</div>
				<div className="dashboard-header-right-notification">
					<RxBell className="dashboard-header-right-icon" />
				</div>
				<div
					onClick={() => setDropdown(!dropdown)}
					ref={dropdownRef}
					className="dashboard-header-right-user"
				>
					<div className="dashboard-header-right-user-name">dasangsherlaam</div>
					<div className="dashboard-header-right-user-avatar">
						<img src={avatar} alt="" className="avatar mr-0" />
					</div>
					<div className="dashboard-header-right-user-dropdown">
						<RxTriangleDown
							className={
								dropdown
									? 'dashboard-header-right-user-dropdown-icon-active'
									: 'dashboard-header-right-user-dropdown-icon'
							}
						/>
					</div>
					<div
						className={
							dropdown
								? 'dashboard-header-right-user-dropdown-list dashboard-header-right-user-dropdown-list-active'
								: 'dashboard-header-right-user-dropdown-list'
						}
					>
						<a
							href="/dashboard/change-password"
							className="dashboard-header-right-user-dropdown-list-item"
						>
							<HiOutlineKey className="dashboard-header-right-user-dropdown-list-item-icon" />{' '}
							change password
						</a>
						<a
							href="/dashboard"
							className="dashboard-header-right-user-dropdown-list-item"
						>
							<RxExit className="dashboard-header-right-user-dropdown-list-item-icon" />{' '}
							logout
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardHeader;
