import React from 'react';
import { Link } from 'react-router-dom';
import {
	RiFacebookBoxLine,
	RiInstagramLine,
	RiLinkedinBoxLine,
	RiTwitterLine,
} from 'react-icons/ri';
import { styles } from '../style';
import { categories, footerLinks } from '../constants';

const Footer = () => {
	//Find useful links from footer links
	const usefulLinks = footerLinks.find((link) => {
		return link.id === 'useful-links';
	});

	//Find legal information links from footer links
	const legalInformation = footerLinks.find((link) => {
		return link.id === 'legal-information';
	});

	return (
		<div className={`footer-container ${styles.sectionPaddingX}`}>
			<div className="footer-content">
				<div className="footer-content-company-info">
					<div
						className={`footer-content-company-info-logo ${styles.heading1}`}
					>
						<Link to="/">Dasang Blogs</Link>
					</div>
					<div className="footer-content-company-info-description">
						<p>
							Websites that provides you the most interesting & educational
							blogs.
						</p>
					</div>
					<div className="footer-content-social-links">
						<div className="footer-content-social-links-title">
							social links
						</div>
						<div className="footer-content-social-links-icons">
							<a
								href="https://www.facebook.com"
								target="_blank"
								rel="noreferrer"
							>
								<RiFacebookBoxLine className="footer-content-social-links-icon" />
							</a>
							<a
								href="https://www.instagram.com"
								target="_blank"
								rel="noreferrer"
							>
								<RiInstagramLine className="footer-content-social-links-icon" />
							</a>
							<a
								href="https://www.linkedin.com"
								target="_blank"
								rel="noreferrer"
							>
								<RiLinkedinBoxLine className="footer-content-social-links-icon" />
							</a>
							<a
								href="https://www.twitter.com"
								target="_blank"
								rel="noreferrer"
							>
								<RiTwitterLine className="footer-content-social-links-icon" />
							</a>
						</div>
					</div>
				</div>
				<div className="footer-content-links-section">
					<div className="footer-content-links">
						<span className="footer-content-links-heading">
							categories
							<div className="footer-content-links-heading-underline"></div>
						</span>
						<div className="footer-content-links-lists">
							{categories.map((category) => (
								<li key={category.id}>
									<Link to={`/category/${category.slug}`}>
										{category.title}
									</Link>
								</li>
							))}
						</div>
					</div>
					<div className="footer-content-links">
						<div className="footer-content-links-heading">
							quick links
							<div className="footer-content-links-heading-underline"></div>
						</div>
						<div className="footer-content-links-lists">
							{usefulLinks.links.map((link) => (
								<li key={link.id}>
									<Link to={`/${link.slug}`}>{link.title}</Link>
								</li>
							))}
						</div>
					</div>
					<div className="footer-content-links">
						<div className="footer-content-links-heading">
							legal information
							<div className="footer-content-links-heading-underline"></div>
						</div>
						<div className="footer-content-links-lists">
							{legalInformation.links.map((link) => (
								<li key={link.id}>
									<Link to={`/${link.slug}`}>{link.title}</Link>
								</li>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="footer-copyright">
				Copyright &#169; 2023 dasangsherlaam.com. All Rights Reserved.
			</div>
		</div>
	);
};

export default Footer;
