import React, {useState, useEffect} from 'react';
import { comments } from '../constants';
import {RiReplyFill, RiArrowDownSLine} from 'react-icons/ri'
import {FormInput} from '../components';

const CommentItem = ({comment}) => {
const [isViewReply, setIsViewReply] = useState(false);

const [isReplying, setIsReplying] = useState(false);

const [newReply, setNewReply] = useState('');

const [hasReply, setHasReply] = useState(false);

useEffect(()=> {
    //Check whether a comment has any replies
    const replies = comments.filter((reply) => reply.parent_id === comment.id);

    if(replies.length) {
        setHasReply(true)
    }
    else {
        setHasReply(false);
    }
}, []);

const handleOnChangeCommentReply = (e) => {
    setNewReply(e.target.value);
};

const handleOnSubmitCommentReply = (e) => {
    e.preventDefault()
    //API call here
    setNewReply('');
    setIsReplying(false);
    setIsViewReply(true);
}

  return (
    <div className="blog-comment-list-item">
        <div className="blog-comment-avatar">
            <img src={comment.avatar} alt="" />
            <span></span>
        </div>
        <div className="blog-comment-details">
            <div className="blog-comment-username">
                {comment.username}...
                <div className="blog-comment-date">
                        {comment.date}
                </div>
            </div>
            <div className="blog-comment-text">
                {comment.description}
            </div>
            <div className="blog-comment-footer">
                <div className="blog-comment-footer-reply">
                    {
                        hasReply && (
                            <button className={isViewReply ? 'blog-comment-footer-view-reply border-[1px] border-colorBgHover' : 'blog-comment-footer-view-reply'} onClick={() =>{setIsViewReply((prev) => !prev); setIsReplying(false)}}>
                                {isViewReply ? 'hide replies' : `view replies`}<RiArrowDownSLine className={isViewReply ? '-rotate-90 duration-300' : 'duration-300'}/>
                            </button>
                        )
                    }
                    <button className={isReplying ? 'blog-comment-footer-write-reply opacity-100' : 'blog-comment-footer-write-reply'} onClick={() => {setIsReplying(true); setIsViewReply(false)}}>
                        <RiReplyFill/> reply
                    </button>
                </div>
            </div>
            {
                isReplying && (
                    <div className="blog-comment-reply-input">
                        <form onSubmit={handleOnSubmitCommentReply}>
                            <FormInput 
                            tag="textarea"
                            id="reply" 
                            value={newReply}
                            name="reply"
                            rows="4" 
                            placeholder={`reply to ${comment.username}`}
                            onChange={handleOnChangeCommentReply}
                            >
                                <div className="blog-comment-reply-input-btn">
                                    <button className="reply-btn-cancel" onClick={() => {setIsReplying(false); setNewReply('') }}>cancel</button>
                                    <button className="reply-btn-submit" type="submit" disabled={newReply === '' ? true : false}>reply</button>
                                </div>
                            </FormInput>
                        </form>
                    </div>
                )
            }
            {
            isViewReply && 
                comments.filter((reply) => reply.parent_id === comment.id).map(reply => (
                <div key={reply.id} className='blog-comment-reply'>
                    <div className="blog-comment-reply-avatar">
                        <img src={reply.avatar} alt=""/>
                        <span></span>
                    </div>
                    <div className="blog-comment-reply-details">
                        <div className='blog-comment-reply-username'>
                            {reply.username}...
                            <div className="blog-comment-reply-date">
                                {reply.date}
                            </div>   
                        </div>
                        <div className="blog-comment-reply-text">
                            {reply.description}
                        </div>
                    </div> 
                </div>                                            
            ))}
        </div>
    </div>
  )
}

export default CommentItem