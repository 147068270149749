import React from 'react'
import { useState, useEffect} from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

const CustomSelect = ({options, getData, errorIcon, errorStatus, reset, setReset}) => {
    const [dropdown, setDropdown] = useState(false);

    const [selected, setSelected] = useState('');

    useEffect(() => {
        if(reset && selected !== '') {
            setSelected('');
            setReset(false);
        }
        getData(selected);
    }, [selected, reset]);

  return (
    <div className='custom-select form-input-container'>
        <div className="custom-select-selected form-input" onClick={() => setDropdown((prev) => !prev)}>
            {selected ? selected : 'Select a category'}
            <RiArrowDownSLine className={dropdown ? 'custom-select-dropdown-icon-active' : 'custom-select-dropdown-icon'}/>
            <div className={dropdown ? 'custom-select-dropdown-options form-input' : 'custom-select-dropdown-options-hidden'}>
                {options.map(option => (
                    <span key={option.id} 
                    onClick={() => setSelected(option.title)}
                    >
                        {option.title}</span>
                ))}
            </div>
        </div>
        {
            errorStatus && selected.length === 0 ?   <div className="input-error">
            {errorIcon}
            <p className='input-error-text'>Please select a category</p>
		    </div> : ''
            }
    </div>
        
  )
}

export default CustomSelect