import React from 'react';
import { Link } from 'react-router-dom';
import { BiCheckShield } from 'react-icons/bi';
import { styles } from '../style';
import {
	lock,
	browser1,
	browser2,
	browser3,
	shield,
	laptop,
} from '../assets/images';

const PrivacyPolicy = () => {
	return (
		<div className="policy-page">
			<div
				className={`policy-page-banner ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<div className="policy-page-banner-left">
					<div className="policy-page-banner-heading">
						Privacy Policy{' '}
						<span className="policy-page-banner-heading-line"></span>
					</div>
					<div
						className={`policy-page-banner-description ${styles.paragraph1}`}
					>
						Welcome to <span>dasangsherlaam.com</span>. This Privacy Policy
						explains how we collect, use, disclose, and safeguard your personal
						data when you use our website. By using our website, you consent to
						the practices described in this policy.
					</div>
					<div className="policy-page-banner-btn">
						<a href="#policy-page-banner-btn-target" className="btn-primary">
							Learn more
						</a>
					</div>
				</div>
				<div className="policy-page-banner-right">
					<div className="policy-page-banner-right-img-container">
						<img
							className="policy-page-banner-right-img-laptop"
							src={laptop}
							alt=""
						/>
						<div className="policy-page-banner-right-img-shield-container">
							<img
								className="policy-page-banner-right-img-shield"
								src={shield}
								alt=""
							/>
						</div>
						<div className="policy-page-banner-right-img-lock-container">
							<img
								src={lock}
								alt=""
								className="policy-page-banner-right-img-lock"
							/>
						</div>
						<div className="policy-page-banner-right-img-browser-container">
							<div className="policy-page-banner-right-img-browsers">
								<img
									src={browser1}
									alt=""
									className="policy-page-banner-right-img-browser1"
								/>
								<img
									src={browser2}
									alt=""
									className="policy-page-banner-right-img-browser2"
								/>
								<img
									src={browser3}
									alt=""
									className="policy-page-banner-right-img-browser3"
								/>
							</div>
						</div>
					</div>
				</div>
				<div id="policy-page-banner-btn-target">privacy policy btn target</div>
			</div>
			<div
				className={`policy-page-content ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">1. information we collect</span>
							<div className="policy-page-content-heading-tag-text">
								1. information we collect
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<span>1. Personal Information:</span>
							<div className={`${styles.paragraph3}`}>
								We may collect personal information that you provide directly to
								us, such as your name, email address, and contact details when
								you interact with our website.
							</div>
						</div>
						<div className="policy-page-content-text-list">
							<span>2. Usage data :</span>
							<div className={`${styles.paragraph3}`}>
								We automatically collect certain information about your device
								and usage patterns when you visit our website, including your IP
								address, browser type, pages visited, and referring URL.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">2. how we use your information</span>
							<div className="policy-page-content-heading-tag-text">
								2. how we use your information
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<span>
								We use the collected information for various purposes, including
								:
							</span>
							<div className="policy-page-content-text-bullets">
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										Providing and improving our services.
									</div>
								</div>
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										Responding to your inquiries and requests.
									</div>
								</div>
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										Sending your promotional and informational content.
									</div>
								</div>
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										Analyzing and improving the effectiveness of our website.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">3. data sharing and disclosure</span>
							<div className="policy-page-content-heading-tag-text">
								3. data sharing and disclosure
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<span>
								We may share your personal data with third parties in the
								following circumstances :
							</span>
							<div
								className={`policy-page-content-text-bullets ${styles.paragraph3}`}
							>
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										With service providers who assist us in operating and
										managing our website.
									</div>
								</div>
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										With affiliated partners for marketing and promotional
										purposes.
									</div>
								</div>
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										When required by law or to protect our rights and interests.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">4. cookies</span>
							<div className="policy-page-content-heading-tag-text">
								4. cookies
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								Our websites uses cookies and similar technologies to collect
								information about your browsing activities. You can manage your
								cookie prefrences through your browser settings.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">5. your rights</span>
							<div className="policy-page-content-heading-tag-text">
								5. your rights
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								You have certain rights regarding your personal data, including
								the right to access, correct, delete, or withdraw your consent.
								You can exercise these rights by contacting us at
								<Link
									to="/contact"
									className="ml-2 text-colorPrimary font-semibold underline"
								>
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">6. data security</span>
							<div className="policy-page-content-heading-tag-text">
								6. data security
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								We implement appropriate security measures to protect your
								personal data from unauthorized access and disclosure. However,
								no data transmission over the internet can be guaranteed to be
								completely secure.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">7. children's privacy</span>
							<div className="policy-page-content-heading-tag-text">
								7. children's privacy
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								Our website is not intended for individuals under the age of 18.
								We do not knowingly collect personal data from children. If you
								believe we have collected information from a child, please
								contact us to have it removed.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">8. changes to this policy</span>
							<div className="policy-page-content-heading-tag-text">
								8. changes to this policy
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								We may update this Privacy Policy to reflect changes in our
								practices or legal requirements. We will post the updated policy
								on this page and revise the "Last Updated" date accordingly.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">9. contact us</span>
							<div className="policy-page-content-heading-tag-text">
								9. contact us
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								If you have any questions about our Privacy Policy or the
								handling of your personal data, please contact us at
								<Link
									to="/contact"
									className="ml-2 text-colorPrimary font-semibold underline"
								>
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
