import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import {
	Home,
	Login,
	Blog,
	Contact,
	Category,
	Search,
	AboutMe,
	PrivacyPolicy,
	CookiePolicy,
	NotFound,
} from '../pages';
import {
	DashboardHome,
	DashboardAll,
	DashboardNew,
	DashboardSettings,
	DashboardProfile,
	DashboardPasswordChange,
	DashboardCategory,
	DashboardPrivacyPolicy,
	DashboardCookiePolicy
} from '../pages/dashboard';

const MainRoutes = createBrowserRouter([
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: '/:category/:title',
				element: <Blog />,
			},
			{
				path: '/category/:title',
				element: <Category />,
			},
			{
				path: '/search/:keyword',
				element: <Search />,
			},
			{
				path: '/contact',
				element: <Contact />,
			},
			{
				path: '/privacy-policy',
				element: <PrivacyPolicy />,
			},
			{
				path: '/cookie-policy',
				element: <CookiePolicy />,
			},
		],
	},
	{
		path: '/dashboard',
		element: <DashboardLayout />,
		children: [
			{
				path: '/dashboard',
				element: <DashboardHome />,
			},
			{
				path: '/dashboard/blogs',
				element: <DashboardAll />,
			},
			{
				path: '/dashboard/blogs/new',
				element: <DashboardNew />,
			},
			{
				path: '/dashboard/categories',
				element: <DashboardCategory />,
			},
			{
				path: '/dashboard/settings',
				element: <DashboardSettings />,
			},
			{
				path:'/dashboard/change-password',
				element: <DashboardPasswordChange/>
			},
			{
				path: '/dashboard/my-profile',
				element: <DashboardProfile />,
			},
			{
				path: '/dashboard/privacy-policy',
				element: <DashboardPrivacyPolicy/>
			},
			{
				path: '/dashboard/cookie-policy',
				element: <DashboardCookiePolicy/>
			}
		],
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/about-me',
		element: <AboutMe />,
	},
	{
		path: '/not-found',
		element: <NotFound code="404" />,
	},
	{
		path: '*',
		element: <Navigate to="/not-found" replace />,
	},
]);

export default MainRoutes;
