import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import imageLoader from '../js/imageloader';

const Thumbnail = ({ blog, category }) => {

	useEffect(() => {
		imageLoader();
	}, [category]);

	return (
		<Link to={`/${blog.category}/${blog.slug}`} className="thumbnail group">
			<div className="thumbnail-img">
				<div className="img-placeholder" style={{backgroundImage: `url(${blog.image_placeholder})`}}>
					<img className="img-original" src={blog.image} alt="" loading="lazy" />
				</div>
			</div>
			<div className="thumbnail-text">
				<div className="thumbnail-text-title">{blog.title}</div>
				<div className="thumbnail-text-date">{blog.date}</div>
			</div>
		</Link>
	);
};

export default Thumbnail;
