import React from 'react'
import {HiOutlineXMark} from 'react-icons/hi2';
import { avatar } from '../../assets/images';
import { styles } from '../../style';
import { HiOutlineCalendarDays } from 'react-icons/hi2';

const DashboardPreview = (props) => {
    
    const currentDate = new Date().toDateString();

    const handlePreviewClose = () => {
        props.handleBlogPreview();
    }

  return (
    <div className="preview-container">
        <div className="preview-close">
            <span onClick={() => handlePreviewClose()}>
            <HiOutlineXMark className="mr-2"/> close 
            </span>
        </div>
        <div className="preview-content">
            <div
                    className={`single-blog ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
                >
                <div className="single-blog-content">
                    <div className={`single-blog-heading ${styles.heading2}`}>
                        {props.title}
                    </div>
                    <div className={`single-blog-content-info`}>
                        <div className="single-blog-content-info-text">
                            <span className="single-blog-content-info-text-date">
                                <HiOutlineCalendarDays className="mr-1" /> {currentDate} {' '}
                            </span>
                            <span className="font-extrabold mx-3">|</span>{' '}
                            <img src={avatar} alt="profile_image" className="avatar" />
                            <span className='font-normal'>Dasanghserlaam</span>
                        </div>
                    </div>
                    <div className="single-blog-main-img">
						<div className="single-blog-main-img-container">
								<img src={props.image} alt="blog_image" loading="lazy" />
							<div className="single-blog-category-tag">
								<div className="single-blog-category-tag-border">
									<span className="invisible">{props.category}</span>
									<div className="single-blog-category-tag-text">
										{props.category}
									</div>
								</div>
							</div>
						</div>
					</div>
                    <div
                        className={`single-blog-content-description-container ${styles.paragraph2}`}
                    >
                        {' '}
                        <div dangerouslySetInnerHTML={{ __html: props.content}} className="single-blog-content-description"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DashboardPreview