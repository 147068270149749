import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {MdCheck, MdError} from 'react-icons/md';
import {styles} from '../style';
import {newsletterImg} from '../assets/images';

const Newsletter = () => {

  const [email, setEmail] = useState('');

  const [agreement, setAgreement] = useState(false);
  
  const [error, setError] = useState(false);

  //Handle onChange 
  const handleOnChange = (e) => {
    setEmail(e.target.value);
  }

  //Handle onSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if(email !== '' && agreement) {
      const formData = new FormData();

      formData.append('email', email);
      formData.append('agreement', agreement);
      //API call here
    }
    else {
      setError(true);
    }
  }


  return (
    <div className={`newsletter-container ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}>
      <div className='newsletter-left'>
          <img src={newsletterImg} alt="" loading="lazy"/>
      </div>
      <div className='newsletter-right'>
        <p>subscribe to our</p>
        <h1 className={styles.heading2}>newsletter</h1>
        <p>for latest blogs and updates</p>
          <form onSubmit={handleSubmit}>
              <div className="newsletter-input-group">
                <input className="newsletter-input" type="email" name="email" value={email} placeholder='your email address' onChange={handleOnChange}/>
                <button className="newsletter-submit" type="submit">subscribe</button>
              </div>
              {error && email === '' ? 
                <div className='input-error'>
                  <MdError/>
                  <span className='input-error-text'>
                    Please enter email address
                  </span>
                </div> : ''
              }
              <div className="newsletter-agreement">
                <span className={agreement ? 'custom-checkbox-active' : 'custom-checkbox'} onClick={() => setAgreement(!agreement)}>
                  <MdCheck className={agreement ? 'check-icon' : 'check-icon opacity-0'}/>
                </span>
                <p>I agree with <Link to="/privacy-policy">privacy policy</Link> </p>
              </div>
              {error && !agreement ? <div className='input-error'>
                  <MdError/>
                  <span className='input-error-text'>
                    Privacy policy agreement required
                  </span>
                </div> : ''}
          </form>
      </div>
    </div>
  )
}

export default Newsletter