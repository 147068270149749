import React from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineCalendarDays} from 'react-icons/hi2';
import { styles } from '../style';
import {Star} from '../components'

const Card = ({ blog, type }) => {
	return (
		<Link to={`/${blog.category}/${blog.slug}`} className="card-link">
			<div className="card group" key={blog.id}>
				<div className="card-img">
					<div className="card-img-container">
						<div className="img-placeholder" style={{backgroundImage: `url(${blog.image_placeholder})` }}>
							<img className="img-original" src={blog.image} alt="" loading="lazy" />
						</div>
						<div className="card-category-tag">
							<div className="card-category-tag-border">
								<span className="invisible">{blog.category}</span>
								<div className="card-category-tag-text">{blog.category}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card-content">
					{type !== 'thumbnail' && (
						<div className="card-content-date">
							<HiOutlineCalendarDays className="mr-2" />
							{blog.date}
						</div>
					)}
					<div className={`card-content-title ${styles.heading4}`}>
						{blog.title}
					</div>
					<div className="card-content-ratings">
						<Star ratings={blog.ratings}/>
						<span>
							({blog.ratings}/5)
						</span>
					</div>
					{type !== 'thumbnail' && (
						<p className={`card-content-description ${styles.paragraph3}`}>
							{blog.description.substring(0, 100)}...
						</p>
					)}
				</div>
			</div>
		</Link>
	);
};

export default Card;
