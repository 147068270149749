import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TbMenu2, TbX } from 'react-icons/tb';
import {
	HiChevronDown,
	HiOutlineMagnifyingGlass,
	HiXMark,
	HiOutlineEnvelope,
	HiOutlineExclamationCircle,
	HiOutlineMagnifyingGlassCircle,
	HiBolt
} from 'react-icons/hi2';
import { styles } from '../style';
import { navLinks } from '../constants/index';
import StickyNavStatus from '../hooks/StickyNavStatus';
import { blogs } from '../constants';
import { MegaMenu, Thumbnail } from './index';
import imageLoader from '../js/imageloader';

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false);

	const [toggleMegaMenu, setToggleMegaMenu] = useState(false);

	const [toggleSearch, setToggleSearch] = useState(false);

	const [resetMegaMenu, setResetMegaMenu] = useState(false);

	const [searchKeyword, setSearchKeyword] = useState('');

	const stickyNav = StickyNavStatus();

	const location = useLocation();

	const navigate = useNavigate();

	//Hide mega menu & reset mega menu topics on page navigation
	useEffect(() => {
		if (toggleMegaMenu) {
			setToggleMegaMenu(false);
			setResetMegaMenu(true);
			imageLoader();
		}

		if (toggleSearch) {
			setToggleSearch(false);
			imageLoader();
		}

		if (toggleMenu) {
			setToggleMenu(false);
		}
		//eslint-disable-next-line
	}, [location]);

	//Hide mega menu & reset mega menu filter on page scroll
	//Hide mega menu & reset mega menu filter on click
	useEffect(() => {
		if (toggleMegaMenu) {
			setResetMegaMenu(true);
		} else {
			setResetMegaMenu(false);
		}
		//Define a callback function for scroll event
		const handleScroll = () => {
			if (toggleMegaMenu) {
				setToggleMegaMenu(false);
				setResetMegaMenu(true);
			} else {
				setResetMegaMenu(false);
			}
			setToggleMenu(false);
		};

		//Define an event listener
		window.addEventListener('scroll', handleScroll);

		//Remove an event listener
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [toggleMegaMenu]);

	//Set search query string
	const handleSearchInput = (e) => {
		setSearchKeyword(e.target.value);
	};

	//Submit the search query
	const handleSearchSubmit = (e) => {
		e.preventDefault();
		navigate(`/search/${searchKeyword}`);
		setSearchKeyword('');
	};

	return (
		<>
			<nav className={stickyNav ? 'navbar navbar-sticky nav-shadow' : 'navbar'}>
				<div className={`navbar-logo navbar-sticky-logo ${styles.heading1}`}>
					<Link to="/">Dasang Blogs</Link>
				</div>
				<div className="navbar-filter">
					<div
						className={`navbar-filter-title ${
							toggleMegaMenu ? 'text-colorPrimary' : ''
						}`}
						onClick={() => {
							setToggleMegaMenu(!toggleMegaMenu);
						}}
					>
						Read
						<HiChevronDown
							className={`ml-1 ease-linear duration-300 ${
								toggleMegaMenu ? '-rotate-90' : 'rotate-0'
							}`}
						/>{' '}
					</div>
					<div
						className="md:flex hidden navbar-filter-title hover:text-colorPrimary ease-linear duration-100"
						onClick={() => setToggleSearch(!toggleSearch)}
					>
						Search <HiOutlineMagnifyingGlass className="ml-1" />
					</div>
				</div>
				<div className="navbar-links">
					{navLinks.map((navLink) => (
						<li className="navbar-links-list" key={navLink.id}>
							<Link to={`${navLink.path}`} className="navbar-link">
								{navLink.title}
							</Link>
						</li>
					))}
				</div>
				<div className="navbar-links-toggle">
					{toggleMenu ? (
						<TbX
							className="navbar-links-toggle-icon-close"
							onClick={() => setToggleMenu(!toggleMenu)}
						/>
					) : (
						<TbMenu2
							className="navbar-links-toggle-icon-menu"
							onClick={() => setToggleMenu(!toggleMenu)}
						/>
					)}

					<div
						className={`${
							toggleMenu
								? 'navbar-links-toggle-links'
								: 'navbar-links-toggle-links navbar-links-toggle-links-hidden'
						}`}
					>
						<li className="navbar-links-toggle-link">
							<HiOutlineMagnifyingGlassCircle className="navbar-links-toggle-link-icon" />
							<div
								onClick={() => {
									setToggleMenu(!toggleMenu);
									setToggleSearch(!toggleSearch);
								}}
							>
								search
							</div>
						</li>
						<li className="navbar-links-toggle-link">
							<HiOutlineEnvelope className="navbar-links-toggle-link-icon" />
							<Link
								to="/contact"
								onClick={() => {
									setToggleMenu(!toggleMenu);
								}}
							>
								contact
							</Link>
						</li>
						<li className="navbar-links-toggle-link">
							<HiOutlineExclamationCircle className="navbar-links-toggle-link-icon" />
							<Link
								to="/about-me"
								onClick={() => {
									setToggleMenu(!toggleMenu);
								}}
							>
								about me
							</Link>
						</li>
					</div>
				</div>
			</nav>
			<div
				className={`navbar-mega-menu-container ${
					toggleMegaMenu ? 'navbar-mega-menu-active ' : 'navbar-mega-menu-hide '
				} ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<MegaMenu resetMegaMenu={resetMegaMenu} />
			</div>
			<div
				className={`${
					toggleSearch
						? 'search-container search-container-active'
						: 'search-container'
				}  ${styles.sectionPaddingX}`}
			>
				<div className="search-container-header">
					<div className="search-close-btn">
						<HiXMark onClick={() => setToggleSearch(!toggleSearch)} />
					</div>
					<div className="search-heading">Search</div>
					<form className="search-form" onSubmit={handleSearchSubmit}>
						<input
							type="text"
							className="search-form-input"
							placeholder="what i'm looking for..."
							value={searchKeyword}
							onChange={handleSearchInput}
							required
						/>
						<button type="submit" className="search-btn-submit">
							search
						</button>
					</form>
					<div className="search-trending-heading"><HiBolt className="search-trending-icon"/> trending now</div>
				</div>
				<div className="search-trending-content">
					<div className="search-trending-thumbnails">
						{blogs.slice(0, 12).map((blog) => (
							<Thumbnail key={blog.id} blog={blog} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
