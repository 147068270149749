import {
	blog1,
	blog2,
	blog3,
	blog4,
	blog5,
	blog6,
	blog7,
	blog8,
	blog9,
	blog10,
	blog11,
	blog12,
	blog13,
	blog14,
	blog1Small,
	blog2Small,
	blog3Small,
	blog4Small,
	blog5Small,
	blog6Small,
	blog7Small,
	blog8Small,
	blog9Small,
	blog10Small,
	blog11Small,
	blog12Small,
	blog13Small,
	blog14Small,
	avatar,
	user1,
	user2,
	user3,
	hospitality,
	science,
	history,
	amazing
} from '../assets/images';

import {RxDashboard, RxPencil2, RxReader, RxStack, RxAvatar, RxGear, RxLockClosed, RxCookie } from 'react-icons/rx';

export const navLinks = [
	{
		id: 'contact',
		title: 'Contact',
		path: '/contact',
	},
	{
		id: 'about-me',
		title: 'About Me',
		path: '/about-me',
	},
];

export const footerLinks = [
	{
		id: 'useful-links',
		links: [
			{
				id: 'home',
				title: 'home',
				slug: '',
			},
			{
				id: 'about-me',
				title: 'about me',
				slug: 'about-me',
			},
			{
				id: 'contact',
				title: 'contact',
				slug: 'contact',
			},
		],
	},
	{
		id: 'legal-information',
		links: [
			{
				id: 'privacy-policy',
				title: 'privacy policy',
				slug: 'privacy-policy',
			},
			{
				id: 'cookie-policy',
				title: 'cookie policy',
				slug: 'cookie-policy',
			},
		],
	},
];

export const dashboardLinks = [
	{
		id: 'home',
		title: 'dashboard',
		icon: <RxDashboard />,
		path: '/dashboard',
	},
	{
		id: 'blogs',
		title: 'blogs',
		icon: <RxReader />,
		path: '/dashboard/blogs',
		
	},
	{
		id: 'new-blog',
		title: 'create blog',
		icon: <RxPencil2 />,
		path: '/dashboard/blogs/new',
		
	},
	{
		id: 'categories',
		title: 'categories',
		icon: <RxStack />,
		path: '/dashboard/categories',
	},
	{
		id: 'my-profile',
		title: 'my profile',
		icon: <RxAvatar />,
		path: '/dashboard/my-profile',
	},
	{
		id: 'privacy-policy',
		title: 'privacy policy',
		icon: <RxLockClosed />,
		path: '/dashboard/privacy-policy',
	},
	{
		id: 'cookie-policy',
		title: 'cookie policy',
		icon: <RxCookie />,
		path: '/dashboard/cookie-policy',
	},
	{
		id: 'settings',
		title: 'settings',
		icon: <RxGear />,
		path: '/dashboard/settings',
	},
];

export const categories = [
	{
		id: 1,
		title: 'hospitality',
		slug: 'hospitality',
		description:
			'Welcome to Hospitality Section. You can find interesting & amazing blogs here.',
		backgroundImage: hospitality
	},
	{
		id: 2,
		title: 'science & technology',
		slug: 'science-and-technology',
		description:
			'Welcome to Science & Technology section. We provide you the latest & interesting blogs about science & technology.',
		backgroundImage: science
		},
	{
		id: 3,
		title: 'amazing facts',
		slug: 'amazing-facts',
		description:
			'Welcome to Amazing Facts section. Get ready for the journey of amazing & insteresting facts you have ever read.',
		backgroundImage: amazing
		},
	{
		id: 4,
		title: 'history',
		slug: 'history',
		description:
			'Welcome to History section. We provide you most interesting history from all around the globe.',
		backgroundImage: history
		},
];

export const blogs = [
	{
		id: '1',
		title: 'How to become a graphics designer',
		slug: 'how-to-become-a-graphics-designer',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '1',
		image: blog1,
		image_placeholder: blog1Small,
		date: '23 AUG 2022',
	},
	{
		id: '2',
		title: 'Hotel management & tourism',
		slug: 'hotel-management-and-tourism',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '4',
		image: blog2,
		image_placeholder: blog2Small,
		date: '20 AUG 2022',
	},
	{
		id: '3',
		title: 'All you need to know about sketch?',
		slug: 'all-you-need-to-know-about-sketch',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '4',
		image: blog3,
		image_placeholder: blog3Small,
		date: '4 JUL 2022',
	},
	{
		id: '4',
		title: 'Leads generation with digital marketing',
		slug: 'leads-generation-with-digital-marketing',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '1',
		image: blog4,
		image_placeholder: blog4Small,
		date: '25 JUN 2022',
	},
	{
		id: '5',
		title: 'Capturing the moment in photography ',
		slug: 'capturing-the-moment-in-photography',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '5',
		image: blog5,
		image_placeholder: blog5Small,
		date: '05 JUN 2022',
	},
	{
		id: '6',
		title: 'Are film cameras dead?',
		slug: 'are-films-cameras-dead',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '2',
		image: blog6,
		image_placeholder: blog6Small,
		date: '20 MAY 2022',
	},
	{
		id: '7',
		title: '5 ways to approach the Bitcoin trading process',
		slug: '5-ways-to-approach-the-Bitcoin-trading-process',
		description:
			'When dealing with the approach of the crypto trading world, the absolute most important thing that you have to take care of establishing the perfect foundation that will help you kick-start your trading journey in the right direction. This explains the attention that you have to put on the subject of finding the best way to start the trading journey so that you will get closer to reaching the points of success. All of this might sound like the obvious step you have to take, however, when you are dealing with such a new and interesting segment, you will have to start things from the very beginning. Here, you will get a chance to deal with the basic notion of cryptocurrencies that represent all of the digital values and assets that can be exchanged through the internet. In this process, people can get a chance to trade, invest, purchase, and so much more, just by using these impressive digital values as a medium of making a profit. However, before you proceed with the segment of trading and making profits, you will have to find a designated cryptocurrency that will be the focal point of your trading journey. If you are interested in dealing with Bitcoin and all of the additional opportunities that are incorporated in this popular digital value then you are in the right place.',
		category: 'science & technology',
		ratings: '3',
		image: blog7,
		image_placeholder: blog7Small,
		date: '01 JAN 2023',
	},
	{
		id: '8',
		title: 'What is Chat GPT? What it is used for?',
		slug: 'What-is-Chat-GPT-What-it-is-used-for',
		description:
			'Chat GPT is an AI Chatbot developed by Open AI. The chatbot has a language-based model that the developer fine-tunes for human interaction in a conversational manner. Effectively it’s a simulated chatbot primarily designed for customer service; people use it for various other purposes too though. These range from writing essays to drafting business plans, to generating code. But what is it and what can it really do? It is an AI chatbot auto-generative system created by OpenAI for online customer care. It is a pre-trained generative chat, which makes use of (NLP) Natural Language Processing. The source of its data is textbooks, websites, and various articles, which it uses to model its own language for responding to human interaction.',
		category: 'science & technology',
		ratings: '4',
		image: blog8,
		image_placeholder: blog8Small,
		date: '22 MAR 2023',
	},
	{
		id: '9',
		title: 'Things to know before you go Nepal',
		slug: 'Things-to-know-before-you-go-Nepal',
		description:
			'A trekkers paradise, Nepal combines Himalayan views, golden temples, charming hill villages and jungle wildlife watching to offer one of the worlds great travel destinations. The Nepal Himalaya is the ultimate goal for mountain lovers. Some of the Himalaya’s most iconic and accessible hiking is on offer here, with rugged trails to Everest, the Annapurnas and beyond. Nowhere else can you trek for days in incredible mountain scenery, secure in the knowledge that a hot meal, cosy lodge and warm slice of apple pie await you at the end of the day. Then there is the adrenaline kick of rafting a roaring Nepali river or bungee jumping into a yawning Himalayan gorge. Canyoning, climbing, kayaking, paragliding and mountain biking all offer a rush against the backdrop of some of the worlds most dramatic landscapes.',
		category: 'hospitality',
		ratings: '1',
		image: blog9,
		image_placeholder: blog9Small,
		date: '24 MAR 2023',
	},
	{
		id: '10',
		title: 'Coca cola was originally green ?',
		slug: 'coca-cola-was-originally-green',
		description:
			'The original formula called for caramel to give Coca-Cola its rich brown color, and although the recipe has undergone some changes through the years, none of them affected the ultimate color of the product. Brown also hides impurities in any given batch, something the backroom chemist who invented Coca-Cola in 1886 kept well in mind as he proceeded with his formulation. These days syrup producers and bottlers have no impurities to hide, but back in the three copper kettles in somebodys basement days, covering up what might have inadvertently dropped into the mix was a concern, and brown hid indiscretions remarkably well. Coke has at times been bottled in green glass bottles, which perhaps explains the popularity of this particular rumor.',
		category: 'amazing facts',
		ratings: '4',
		image: blog10,
		image_placeholder: blog10Small,
		date: '26 MAR 2023',
	},
	{
		id: '11',
		title: 'All polar bears are left handed ?',
		slug: 'All-polar-bears-are-left-handed',
		description:
			'According to Polar Bears International : "There is no evidence to support the notion that all great white bears are left pawed. Scientists observing the animals have not noticed a preference. In fact, polar bears seem to use their right and left paws equally." They will just use whichever paw is best for the task at hand and often use both paws at the same time to grab their prey and dig with. The same applies to when they swim - they just use their large front paws to propel themselves forward and their back legs to steer.',
		category: 'amazing facts',
		ratings: '4',
		image: blog11,
		image_placeholder: blog11Small,
		date: '28 MAR 2023',
	},
	{
		id: '12',
		title: 'Who is Nepoleon Bonapart ?',
		slug: 'who-is-nepoleon-bonapart',
		description:
			'Napoleon was born on the 15th of August, 1769, in French occupied Corsica. His father was Carlo Maria di Buonaparte, and his mother, Maria Letizia Ramolino. Napoleon’s father was a strong supporter of the Corsican resistance leader, Pasquale Paoli. When Paoli fled the Corsica for his life, Carlo Buonaparte shifted his allegiance to the side of France. This move allowed him to enroll both of his sons, Joseph and Napoleon, in the French College d’Autun. Napoleon’s return to France prompted a re-commissioning in the French military. He joined his military unit in Nice in June 1793, and threw his political support behind the Jacobins, a progressive and popular party after the French Revolution. The Jacobins came into dictatorial power for a short period that became known as the reign of terror. Fortunately for Napoleon, he fell out of favor with Jacobin leadership, letting him avoid execution, and fell into the good graces of the Government, which he defended from counter-revolutionary fighters in 1795. Because of this he was named commander of the Army of the Interior, and became a trusted military advisor to the Government. In 1796 the Army of Italy, then a client nation of France, was placed under the command of Napoleon. 30,000 malnourished men with low moral were whipped into shape by the young commander, and were led to victories against Austria. In the process, the Italian military greatly expanded the French border.',
		category: 'history',
		ratings: '4',
		image: blog12,
		image_placeholder: blog12Small,
		date: '30 MAR 2023',
	},
	{
		id: '13',
		title: 'Jung Bahadur Rana',
		slug: 'jung-bahadur-rana',
		description:
			'Jung Bahadur was born June 18, 1817, in Kathmandu, Nepal, and died Feb. 25, 1877, in Kathmandu. Jung Bahadur, a man of great courage and ability, took control of the government after killing a usurper, Gagan Singh, who plotted to become prime minister with the junior queen in 1846 and put her son on the throne. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51. Throughout his career, he stayed a strong British buddy.The economic ability and conciliatory strategy of Jung Bahadur assisted Nepal to stay autonomous while the remainder of the Indian subcontinent fell under British rule. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51.',
		category: 'history',
		ratings: '4',
		image: blog13,
		image_placeholder: blog13Small,
		date: '01 APR 2023',
	},
	{
		id: '14',
		title: 'Alexander the great',
		slug: 'alexander-the-great',
		description:
			'Alexander the Great was an ancient Macedonian ruler and one of history’s greatest military minds who, as King of Macedonia and Persia, established the largest empire the ancient world had ever seen. By turns charismatic and ruthless, brilliant and power hungry, diplomatic and bloodthirsty, Alexander inspired such loyalty in his men they’d follow him anywhere and, if necessary, die in the process. Though Alexander the Great died before realizing his dream of uniting a new realm, his influence on Greek and Asian culture was so profound that it inspired a new historical epoch—the Hellenistic Period.',
		category: 'history',
		ratings: '4',
		image: blog14,
		image_placeholder: blog14Small,
		date: '03 APR 2023',
	},
	{
		id: '15',
		title: 'How to become a graphics designer',
		slug: 'how-to-become-a-graphics-designer',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '3',
		image: blog1,
		image_placeholder: blog1Small,
		date: '23 AUG 2022',
	},
	{
		id: '16',
		title: 'Hotel management & tourism',
		slug: 'hotel-management-and-tourism',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '3',
		image: blog2,
		image_placeholder: blog2Small,
		date: '20 AUG 2022',
	},
	{
		id: '17',
		title: 'All you need to know about sketch?',
		slug: 'all-you-need-to-know-about-sketch',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '3',
		image: blog3,
		image_placeholder: blog3Small,
		date: '4 JUL 2022',
	},
	{
		id: '18',
		title: 'Leads generation with digital marketing',
		slug: 'leads-generation-with-digital-marketing',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '3',
		image: blog4,
		image_placeholder: blog4Small,
		date: '25 JUN 2022',
	},
	{
		id: '19',
		title: 'Capturing the moment in photography ',
		slug: 'capturing-the-moment-in-photography',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '3',
		image: blog5,
		image_placeholder: blog5Small,
		date: '05 JUN 2022',
	},
	{
		id: '20',
		title: 'Are film cameras dead?',
		slug: 'are-films-cameras-dead',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '3',
		image: blog6,
		image_placeholder: blog6Small,
		date: '20 MAY 2022',
	},
	{
		id: '21',
		title: '5 ways to approach the Bitcoin trading process',
		slug: '5-ways-to-approach-the-Bitcoin-trading-process',
		description:
			'When dealing with the approach of the crypto trading world, the absolute most important thing that you have to take care of establishing the perfect foundation that will help you kick-start your trading journey in the right direction. This explains the attention that you have to put on the subject of finding the best way to start the trading journey so that you will get closer to reaching the points of success. All of this might sound like the obvious step you have to take, however, when you are dealing with such a new and interesting segment, you will have to start things from the very beginning. Here, you will get a chance to deal with the basic notion of cryptocurrencies that represent all of the digital values and assets that can be exchanged through the internet. In this process, people can get a chance to trade, invest, purchase, and so much more, just by using these impressive digital values as a medium of making a profit. However, before you proceed with the segment of trading and making profits, you will have to find a designated cryptocurrency that will be the focal point of your trading journey. If you are interested in dealing with Bitcoin and all of the additional opportunities that are incorporated in this popular digital value then you are in the right place.',
		category: 'science & technology',
		ratings: '3',
		image: blog7,
		image_placeholder: blog7Small,
		date: '01 JAN 2023',
	},
	{
		id: '22',
		title: 'What is Chat GPT? What it is used for?',
		slug: 'What-is-Chat-GPT-What-it-is-used-for',
		description:
			'Chat GPT is an AI Chatbot developed by Open AI. The chatbot has a language-based model that the developer fine-tunes for human interaction in a conversational manner. Effectively it’s a simulated chatbot primarily designed for customer service; people use it for various other purposes too though. These range from writing essays to drafting business plans, to generating code. But what is it and what can it really do? It is an AI chatbot auto-generative system created by OpenAI for online customer care. It is a pre-trained generative chat, which makes use of (NLP) Natural Language Processing. The source of its data is textbooks, websites, and various articles, which it uses to model its own language for responding to human interaction.',
		category: 'science & technology',
		ratings: '2',
		image: blog8,
		image_placeholder: blog8Small,
		date: '22 MAR 2023',
	},
	{
		id: '23',
		title: 'Things to know before you go Nepal',
		slug: 'Things-to-know-before-you-go-Nepal',
		description:
			'A trekkers paradise, Nepal combines Himalayan views, golden temples, charming hill villages and jungle wildlife watching to offer one of the worlds great travel destinations. The Nepal Himalaya is the ultimate goal for mountain lovers. Some of the Himalaya’s most iconic and accessible hiking is on offer here, with rugged trails to Everest, the Annapurnas and beyond. Nowhere else can you trek for days in incredible mountain scenery, secure in the knowledge that a hot meal, cosy lodge and warm slice of apple pie await you at the end of the day. Then there is the adrenaline kick of rafting a roaring Nepali river or bungee jumping into a yawning Himalayan gorge. Canyoning, climbing, kayaking, paragliding and mountain biking all offer a rush against the backdrop of some of the worlds most dramatic landscapes.',
		category: 'hospitality',
		ratings: '2',
		image: blog9,
		image_placeholder: blog9Small,
		date: '24 MAR 2023',
	},
	{
		id: '24',
		title: 'Coca cola was originally green ?',
		slug: 'coca-cola-was-originally-green',
		description:
			'The original formula called for caramel to give Coca-Cola its rich brown color, and although the recipe has undergone some changes through the years, none of them affected the ultimate color of the product. Brown also hides impurities in any given batch, something the backroom chemist who invented Coca-Cola in 1886 kept well in mind as he proceeded with his formulation. These days syrup producers and bottlers have no impurities to hide, but back in the three copper kettles in somebodys basement days, covering up what might have inadvertently dropped into the mix was a concern, and brown hid indiscretions remarkably well. Coke has at times been bottled in green glass bottles, which perhaps explains the popularity of this particular rumor.',
		category: 'amazing facts',
		ratings: '2',
		image: blog10,
		image_placeholder: blog10Small,
		date: '26 MAR 2023',
	},
	{
		id: '25',
		title: 'All polar bears are left handed ?',
		slug: 'All-polar-bears-are-left-handed',
		description:
			'According to Polar Bears International : "There is no evidence to support the notion that all great white bears are left pawed. Scientists observing the animals have not noticed a preference. In fact, polar bears seem to use their right and left paws equally." They will just use whichever paw is best for the task at hand and often use both paws at the same time to grab their prey and dig with. The same applies to when they swim - they just use their large front paws to propel themselves forward and their back legs to steer.',
		category: 'amazing facts',
		ratings: '2',
		image: blog11,
		image_placeholder: blog11Small,
		date: '28 MAR 2023',
	},
	{
		id: '26',
		title: 'Who is Nepoleon Bonapart ?',
		slug: 'who-is-nepoleon-bonapart',
		description:
			'Napoleon was born on the 15th of August, 1769, in French occupied Corsica. His father was Carlo Maria di Buonaparte, and his mother, Maria Letizia Ramolino. Napoleon’s father was a strong supporter of the Corsican resistance leader, Pasquale Paoli. When Paoli fled the Corsica for his life, Carlo Buonaparte shifted his allegiance to the side of France. This move allowed him to enroll both of his sons, Joseph and Napoleon, in the French College d’Autun. Napoleon’s return to France prompted a re-commissioning in the French military. He joined his military unit in Nice in June 1793, and threw his political support behind the Jacobins, a progressive and popular party after the French Revolution. The Jacobins came into dictatorial power for a short period that became known as the reign of terror. Fortunately for Napoleon, he fell out of favor with Jacobin leadership, letting him avoid execution, and fell into the good graces of the Government, which he defended from counter-revolutionary fighters in 1795. Because of this he was named commander of the Army of the Interior, and became a trusted military advisor to the Government. In 1796 the Army of Italy, then a client nation of France, was placed under the command of Napoleon. 30,000 malnourished men with low moral were whipped into shape by the young commander, and were led to victories against Austria. In the process, the Italian military greatly expanded the French border.',
		category: 'history',
		ratings: '2',
		image: blog12,
		image_placeholder: blog12Small,
		date: '30 MAR 2023',
	},
	{
		id: '27',
		title: 'Jung Bahadur Rana',
		slug: 'jung-bahadur-rana',
		description:
			'Jung Bahadur was born June 18, 1817, in Kathmandu, Nepal, and died Feb. 25, 1877, in Kathmandu. Jung Bahadur, a man of great courage and ability, took control of the government after killing a usurper, Gagan Singh, who plotted to become prime minister with the junior queen in 1846 and put her son on the throne. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51. Throughout his career, he stayed a strong British buddy.The economic ability and conciliatory strategy of Jung Bahadur assisted Nepal to stay autonomous while the remainder of the Indian subcontinent fell under British rule. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51.',
		category: 'history',
		ratings: '5',
		image: blog13,
		image_placeholder: blog13Small,
		date: '01 APR 2023',
	},
	{
		id: '28',
		title: 'Alexander the great',
		slug: 'alexander-the-great',
		description:
			'Alexander the Great was an ancient Macedonian ruler and one of history’s greatest military minds who, as King of Macedonia and Persia, established the largest empire the ancient world had ever seen. By turns charismatic and ruthless, brilliant and power hungry, diplomatic and bloodthirsty, Alexander inspired such loyalty in his men they’d follow him anywhere and, if necessary, die in the process. Though Alexander the Great died before realizing his dream of uniting a new realm, his influence on Greek and Asian culture was so profound that it inspired a new historical epoch—the Hellenistic Period.',
		category: 'history',
		ratings: '5',
		image: blog14,
		image_placeholder: blog14Small,
		date: '03 APR 2023',
	},
	{
		id: '29',
		title: 'How to become a graphics designer',
		slug: 'how-to-become-a-graphics-designer',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '5',
		image: blog1,
		image_placeholder: blog1Small,
		date: '23 AUG 2022',
	},
	{
		id: '30',
		title: 'Hotel management & tourism',
		slug: 'hotel-management-and-tourism',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '5',
		image: blog2,
		image_placeholder: blog2Small,
		date: '20 AUG 2022',
	},
	{
		id: '31',
		title: 'All you need to know about sketch?',
		slug: 'all-you-need-to-know-about-sketch',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '5',
		image: blog3,
		image_placeholder: blog3Small,
		date: '4 JUL 2022',
	},
	{
		id: '32',
		title: 'Leads generation with digital marketing',
		slug: 'leads-generation-with-digital-marketing',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '5',
		image: blog4,
		image_placeholder: blog4Small,
		date: '25 JUN 2022',
	},
	{
		id: '33',
		title: 'Capturing the moment in photography ',
		slug: 'capturing-the-moment-in-photography',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '3',
		image: blog5,
		image_placeholder: blog5Small,
		date: '05 JUN 2022',
	},
	{
		id: '34',
		title: 'Are film cameras dead?',
		slug: 'are-films-cameras-dead',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '3',
		image: blog6,
		image_placeholder: blog6Small,
		date: '20 MAY 2022',
	},
	{
		id: '35',
		title: '5 ways to approach the Bitcoin trading process',
		slug: '5-ways-to-approach-the-Bitcoin-trading-process',
		description:
			'When dealing with the approach of the crypto trading world, the absolute most important thing that you have to take care of establishing the perfect foundation that will help you kick-start your trading journey in the right direction. This explains the attention that you have to put on the subject of finding the best way to start the trading journey so that you will get closer to reaching the points of success. All of this might sound like the obvious step you have to take, however, when you are dealing with such a new and interesting segment, you will have to start things from the very beginning. Here, you will get a chance to deal with the basic notion of cryptocurrencies that represent all of the digital values and assets that can be exchanged through the internet. In this process, people can get a chance to trade, invest, purchase, and so much more, just by using these impressive digital values as a medium of making a profit. However, before you proceed with the segment of trading and making profits, you will have to find a designated cryptocurrency that will be the focal point of your trading journey. If you are interested in dealing with Bitcoin and all of the additional opportunities that are incorporated in this popular digital value then you are in the right place.',
		category: 'science & technology',
		ratings: '3',
		image: blog7,
		image_placeholder: blog7Small,
		date: '01 JAN 2023',
	},
	{
		id: '36',
		title: 'What is Chat GPT? What it is used for?',
		slug: 'What-is-Chat-GPT-What-it-is-used-for',
		description:
			'Chat GPT is an AI Chatbot developed by Open AI. The chatbot has a language-based model that the developer fine-tunes for human interaction in a conversational manner. Effectively it’s a simulated chatbot primarily designed for customer service; people use it for various other purposes too though. These range from writing essays to drafting business plans, to generating code. But what is it and what can it really do? It is an AI chatbot auto-generative system created by OpenAI for online customer care. It is a pre-trained generative chat, which makes use of (NLP) Natural Language Processing. The source of its data is textbooks, websites, and various articles, which it uses to model its own language for responding to human interaction.',
		category: 'science & technology',
		ratings: '3',
		image: blog8,
		image_placeholder: blog8Small,
		date: '22 MAR 2023',
	},
	{
		id: '37',
		title: 'Things to know before you go Nepal',
		slug: 'Things-to-know-before-you-go-Nepal',
		description:
			'A trekkers paradise, Nepal combines Himalayan views, golden temples, charming hill villages and jungle wildlife watching to offer one of the worlds great travel destinations. The Nepal Himalaya is the ultimate goal for mountain lovers. Some of the Himalaya’s most iconic and accessible hiking is on offer here, with rugged trails to Everest, the Annapurnas and beyond. Nowhere else can you trek for days in incredible mountain scenery, secure in the knowledge that a hot meal, cosy lodge and warm slice of apple pie await you at the end of the day. Then there is the adrenaline kick of rafting a roaring Nepali river or bungee jumping into a yawning Himalayan gorge. Canyoning, climbing, kayaking, paragliding and mountain biking all offer a rush against the backdrop of some of the worlds most dramatic landscapes.',
		category: 'hospitality',
		ratings: '3',
		image: blog9,
		image_placeholder: blog9Small,
		date: '24 MAR 2023',
	},
	{
		id: '38',
		title: 'Coca cola was originally green ?',
		slug: 'coca-cola-was-originally-green',
		description:
			'The original formula called for caramel to give Coca-Cola its rich brown color, and although the recipe has undergone some changes through the years, none of them affected the ultimate color of the product. Brown also hides impurities in any given batch, something the backroom chemist who invented Coca-Cola in 1886 kept well in mind as he proceeded with his formulation. These days syrup producers and bottlers have no impurities to hide, but back in the three copper kettles in somebodys basement days, covering up what might have inadvertently dropped into the mix was a concern, and brown hid indiscretions remarkably well. Coke has at times been bottled in green glass bottles, which perhaps explains the popularity of this particular rumor.',
		category: 'amazing facts',
		ratings: '3',
		image: blog10,
		image_placeholder: blog10Small,
		date: '26 MAR 2023',
	},
	{
		id: '39',
		title: 'All polar beers are left handed ?',
		slug: 'All-polar-beers-are-left-handed',
		description:
			'According to Polar Bears International : "There is no evidence to support the notion that all great white bears are left pawed. Scientists observing the animals have not noticed a preference. In fact, polar bears seem to use their right and left paws equally." They will just use whichever paw is best for the task at hand and often use both paws at the same time to grab their prey and dig with. The same applies to when they swim - they just use their large front paws to propel themselves forward and their back legs to steer.',
		category: 'amazing facts',
		ratings: '1',
		image: blog11,
		image_placeholder: blog11Small,
		date: '28 MAR 2023',
	},
	{
		id: '40',
		title: 'Who is Nepoleon Bonapart ?',
		slug: 'who-is-nepoleon-bonapart',
		description:
			'Napoleon was born on the 15th of August, 1769, in French occupied Corsica. His father was Carlo Maria di Buonaparte, and his mother, Maria Letizia Ramolino. Napoleon’s father was a strong supporter of the Corsican resistance leader, Pasquale Paoli. When Paoli fled the Corsica for his life, Carlo Buonaparte shifted his allegiance to the side of France. This move allowed him to enroll both of his sons, Joseph and Napoleon, in the French College d’Autun. Napoleon’s return to France prompted a re-commissioning in the French military. He joined his military unit in Nice in June 1793, and threw his political support behind the Jacobins, a progressive and popular party after the French Revolution. The Jacobins came into dictatorial power for a short period that became known as the reign of terror. Fortunately for Napoleon, he fell out of favor with Jacobin leadership, letting him avoid execution, and fell into the good graces of the Government, which he defended from counter-revolutionary fighters in 1795. Because of this he was named commander of the Army of the Interior, and became a trusted military advisor to the Government. In 1796 the Army of Italy, then a client nation of France, was placed under the command of Napoleon. 30,000 malnourished men with low moral were whipped into shape by the young commander, and were led to victories against Austria. In the process, the Italian military greatly expanded the French border.',
		category: 'history',
		ratings: '1',
		image: blog12,
		image_placeholder: blog12Small,
		date: '30 MAR 2023',
	},
	{
		id: '41',
		title: 'Jung Bahadur Rana',
		slug: 'jung-bahadur-rana',
		description:
			'Jung Bahadur was born June 18, 1817, in Kathmandu, Nepal, and died Feb. 25, 1877, in Kathmandu. Jung Bahadur, a man of great courage and ability, took control of the government after killing a usurper, Gagan Singh, who plotted to become prime minister with the junior queen in 1846 and put her son on the throne. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51. Throughout his career, he stayed a strong British buddy.The economic ability and conciliatory strategy of Jung Bahadur assisted Nepal to stay autonomous while the remainder of the Indian subcontinent fell under British rule. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51.',
		category: 'history',
		ratings: '1',
		image: blog13,
		image_placeholder: blog13Small,
		date: '01 APR 2023',
	},
	{
		id: '42',
		title: 'Alexander the great',
		slug: 'alexander-the-great',
		description:
			'Alexander the Great was an ancient Macedonian ruler and one of history’s greatest military minds who, as King of Macedonia and Persia, established the largest empire the ancient world had ever seen. By turns charismatic and ruthless, brilliant and power hungry, diplomatic and bloodthirsty, Alexander inspired such loyalty in his men they’d follow him anywhere and, if necessary, die in the process. Though Alexander the Great died before realizing his dream of uniting a new realm, his influence on Greek and Asian culture was so profound that it inspired a new historical epoch—the Hellenistic Period.',
		category: 'history',
		ratings: '1',
		image: blog14,
		image_placeholder: blog14Small,
		date: '03 APR 2023',
	},
	{
		id: '43',
		title: 'How to become a graphics designer',
		slug: 'how-to-become-a-graphics-designer',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '1',
		image: blog1,
		image_placeholder: blog1Small,
		date: '23 AUG 2022',
	},
	{
		id: '44',
		title: 'Hotel management & tourism',
		slug: 'hotel-management-and-tourism',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '2',
		image: blog2,
		image_placeholder: blog2Small,
		date: '20 AUG 2022',
	},
	{
		id: '45',
		title: 'All you need to know about sketch?',
		slug: 'all-you-need-to-know-about-sketch',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '2',
		image: blog3,
		image_placeholder: blog3Small,
		date: '4 JUL 2022',
	},
	{
		id: '46',
		title: 'Leads generation with digital marketing',
		slug: 'leads-generation-with-digital-marketing',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'science & technology',
		ratings: '2',
		image: blog4,
		image_placeholder: blog4Small,
		date: '25 JUN 2022',
	},
	{
		id: '47',
		title: 'Capturing the moment in photography ',
		slug: 'capturing-the-moment-in-photography',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'hospitality',
		ratings: '2',
		image: blog5,
		image_placeholder: blog5Small,
		date: '05 JUN 2022',
	},
	{
		id: '48',
		title: 'Are film cameras dead?',
		slug: 'are-films-cameras-dead',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate odit nostrum placeat sit aliquid corrupti, nemo quas necessitatibus? Veritatis temporibus nisi excepturi, doloribus illum dolorem aliquam magnam iste earum adipisci delectus quod aut vero voluptatibus in enim sed libero blanditiis, saepe voluptates? At minima commodi aut voluptates! Atque, voluptas. Soluta odit iusto aperiam quae voluptatibus corrupti voluptates hic at neque tenetur. Quae cum nobis error accusamus consequuntur, officiis eum aliquam excepturi! Nesciunt dolores sequi ab blanditiis laborum similique dolore nulla tempore iure assumenda impedit id adipisci incidunt, inventore eligendi sunt consequuntur suscipit, maiores expedita. Beatae maiores at amet non aliquam!',
		category: 'amazing facts',
		ratings: '2',
		image: blog6,
		image_placeholder: blog6Small,
		date: '20 MAY 2022',
	},
	{
		id: '49',
		title: '5 ways to approach the Bitcoin trading process',
		slug: '5-ways-to-approach-the-Bitcoin-trading-process',
		description:
			'When dealing with the approach of the crypto trading world, the absolute most important thing that you have to take care of establishing the perfect foundation that will help you kick-start your trading journey in the right direction. This explains the attention that you have to put on the subject of finding the best way to start the trading journey so that you will get closer to reaching the points of success. All of this might sound like the obvious step you have to take, however, when you are dealing with such a new and interesting segment, you will have to start things from the very beginning. Here, you will get a chance to deal with the basic notion of cryptocurrencies that represent all of the digital values and assets that can be exchanged through the internet. In this process, people can get a chance to trade, invest, purchase, and so much more, just by using these impressive digital values as a medium of making a profit. However, before you proceed with the segment of trading and making profits, you will have to find a designated cryptocurrency that will be the focal point of your trading journey. If you are interested in dealing with Bitcoin and all of the additional opportunities that are incorporated in this popular digital value then you are in the right place.',
		category: 'science & technology',
		ratings: '2',
		image: blog7,
		image_placeholder: blog7Small,
		date: '01 JAN 2023',
	},
	{
		id: '50',
		title: 'What is Chat GPT? What it is used for?',
		slug: 'What-is-Chat-GPT-What-it-is-used-for',
		description:
			'Chat GPT is an AI Chatbot developed by Open AI. The chatbot has a language-based model that the developer fine-tunes for human interaction in a conversational manner. Effectively it’s a simulated chatbot primarily designed for customer service; people use it for various other purposes too though. These range from writing essays to drafting business plans, to generating code. But what is it and what can it really do? It is an AI chatbot auto-generative system created by OpenAI for online customer care. It is a pre-trained generative chat, which makes use of (NLP) Natural Language Processing. The source of its data is textbooks, websites, and various articles, which it uses to model its own language for responding to human interaction.',
		category: 'science & technology',
		ratings: '2',
		image: blog8,
		image_placeholder: blog8Small,
		date: '22 MAR 2023',
	},
	{
		id: '51',
		title: 'Things to know before you go Nepal',
		slug: 'Things-to-know-before-you-go-Nepal',
		description:
			'A trekkers paradise, Nepal combines Himalayan views, golden temples, charming hill villages and jungle wildlife watching to offer one of the worlds great travel destinations. The Nepal Himalaya is the ultimate goal for mountain lovers. Some of the Himalaya’s most iconic and accessible hiking is on offer here, with rugged trails to Everest, the Annapurnas and beyond. Nowhere else can you trek for days in incredible mountain scenery, secure in the knowledge that a hot meal, cosy lodge and warm slice of apple pie await you at the end of the day. Then there is the adrenaline kick of rafting a roaring Nepali river or bungee jumping into a yawning Himalayan gorge. Canyoning, climbing, kayaking, paragliding and mountain biking all offer a rush against the backdrop of some of the worlds most dramatic landscapes.',
		category: 'hospitality',
		ratings: '3',
		image: blog9,
		image_placeholder: blog9Small,
		date: '24 MAR 2023',
	},
	{
		id: '52',
		title: 'Coca cola was originally green ?',
		slug: 'coca-cola-was-originally-green',
		description:
			'The original formula called for caramel to give Coca-Cola its rich brown color, and although the recipe has undergone some changes through the years, none of them affected the ultimate color of the product. Brown also hides impurities in any given batch, something the backroom chemist who invented Coca-Cola in 1886 kept well in mind as he proceeded with his formulation. These days syrup producers and bottlers have no impurities to hide, but back in the three copper kettles in somebodys basement days, covering up what might have inadvertently dropped into the mix was a concern, and brown hid indiscretions remarkably well. Coke has at times been bottled in green glass bottles, which perhaps explains the popularity of this particular rumor.',
		category: 'amazing facts',
		ratings: '3',
		image: blog10,
		image_placeholder: blog10Small,
		date: '26 MAR 2023',
	},
	{
		id: '53',
		title: 'All polar beers are left handed ?',
		slug: 'All-polar-beers-are-left-handed',
		description:
			'According to Polar Bears International : "There is no evidence to support the notion that all great white bears are left pawed. Scientists observing the animals have not noticed a preference. In fact, polar bears seem to use their right and left paws equally." They will just use whichever paw is best for the task at hand and often use both paws at the same time to grab their prey and dig with. The same applies to when they swim - they just use their large front paws to propel themselves forward and their back legs to steer.',
		category: 'amazing facts',
		ratings: '3',
		image: blog11,
		image_placeholder: blog11Small,
		date: '28 MAR 2023',
	},
	{
		id: '54',
		title: 'Who is Nepoleon Bonapart ?',
		slug: 'who-is-nepoleon-bonapart',
		description:
			'Napoleon was born on the 15th of August, 1769, in French occupied Corsica. His father was Carlo Maria di Buonaparte, and his mother, Maria Letizia Ramolino. Napoleon’s father was a strong supporter of the Corsican resistance leader, Pasquale Paoli. When Paoli fled the Corsica for his life, Carlo Buonaparte shifted his allegiance to the side of France. This move allowed him to enroll both of his sons, Joseph and Napoleon, in the French College d’Autun. Napoleon’s return to France prompted a re-commissioning in the French military. He joined his military unit in Nice in June 1793, and threw his political support behind the Jacobins, a progressive and popular party after the French Revolution. The Jacobins came into dictatorial power for a short period that became known as the reign of terror. Fortunately for Napoleon, he fell out of favor with Jacobin leadership, letting him avoid execution, and fell into the good graces of the Government, which he defended from counter-revolutionary fighters in 1795. Because of this he was named commander of the Army of the Interior, and became a trusted military advisor to the Government. In 1796 the Army of Italy, then a client nation of France, was placed under the command of Napoleon. 30,000 malnourished men with low moral were whipped into shape by the young commander, and were led to victories against Austria. In the process, the Italian military greatly expanded the French border.',
		category: 'history',
		ratings: '3',
		image: blog12,
		image_placeholder: blog12Small,
		date: '30 MAR 2023',
	},
	{
		id: '55',
		title: 'Jung Bahadur Rana',
		slug: 'jung-bahadur-rana',
		description:
			'Jung Bahadur was born June 18, 1817, in Kathmandu, Nepal, and died Feb. 25, 1877, in Kathmandu. Jung Bahadur, a man of great courage and ability, took control of the government after killing a usurper, Gagan Singh, who plotted to become prime minister with the junior queen in 1846 and put her son on the throne. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51. Throughout his career, he stayed a strong British buddy.The economic ability and conciliatory strategy of Jung Bahadur assisted Nepal to stay autonomous while the remainder of the Indian subcontinent fell under British rule. Subsequently, after trying to get him assassinated, he deposed and exiled both the king and the queen. He was nominated for life as prime minister and given Rana’s hereditary title. He toured England during the period 1850–51.',
		category: 'history',
		ratings: '3',
		image: blog13,
		image_placeholder: blog13Small,
		date: '01 APR 2023',
	},
	{
		id: '56',
		title: 'Alexander the great',
		slug: 'alexander-the-great',
		description:
			'Alexander the Great was an ancient Macedonian ruler and one of history’s greatest military minds who, as King of Macedonia and Persia, established the largest empire the ancient world had ever seen. By turns charismatic and ruthless, brilliant and power hungry, diplomatic and bloodthirsty, Alexander inspired such loyalty in his men they’d follow him anywhere and, if necessary, die in the process. Though Alexander the Great died before realizing his dream of uniting a new realm, his influence on Greek and Asian culture was so profound that it inspired a new historical epoch—the Hellenistic Period.',
		category: 'history',
		ratings: '3',
		image: blog14,
		image_placeholder: blog14Small,
		date: '03 APR 2023',
	},
];

export const comments = [
	{
		id: '1',
		parent_id: '',
		blog_id: '',
		username: 'dasang',
		avatar: avatar,
		description: 'What a lovely,cozy,whimsical,fun home. Corries style and use of color reminds me of Beata Heuman and Meta Colemans work which I really enjoy. So enjoyed this hometour. Thanks! I will always visiting this site.',
		date: '03 APR 2023'
	},
	{
		id: '2',
		parent_id: '',
		blog_id: '',
		username: 'hari bahadur',
		avatar: user1,
		description: 'Yesto tah ma pani lekhna sakxu ni ki kaso? ghas katne khurkera aayo joban hurkera kaslai diu yo joba.',
		date: '01 APR 2023'
	},
	{
		id: '3',
		parent_id: '',
		blog_id: '',
		username: 'champaklal',
		avatar: user2,
		description: 'You are more powerful than you know; you are beautiful just as you are. Please stop looking so beautiful every time. Astonishing natural beauty with tremendous ...',
		date: '28 MAR 2023'
	},
	{
		id: '4',
		parent_id: '',
		blog_id: '',
		username: 'jethalal',
		avatar: user3,
		description: 'Cant believe theres finally a photo worth a billion dollars! Has anyone ever seen such beauty? Ill be dreaming of this pic! Youre always blessing ...',
		date: '03 FEB 2023'
	},
	{
		id: '5',
		parent_id: '1',
		blog_id: '',
		username: 'hari bahadur',
		avatar: user1,
		description: 'Yesto tah ma pani lekhna sakxu ni ki kaso? ghas katne khurkera aayo joban hurkera kaslai diu yo joba.',
		date: '01 APR 2023'
	},
	{
		id: '6',
		parent_id: '1',
		blog_id: '',
		username: 'champaklal',
		avatar: user2,
		description: 'You are more powerful than you know; you are beautiful just as you are. Please stop looking so beautiful every time. Astonishing natural beauty with tremendous ...',
		date: '28 MAR 2023'
	},
	{
		id: '7',
		parent_id: '3',
		blog_id: '',
		username: 'jethalal',
		avatar: user3,
		description: 'Cant believe theres finally a photo worth a billion dollars! Has anyone ever seen such beauty? Ill be dreaming of this pic! Youre always blessing ...',
		date: '03 FEB 2023'
	},
	{
		id: '8',
		parent_id: '1',
		blog_id: '',
		username: 'jethalal',
		avatar: user3,
		description: 'Cant believe theres finally a photo worth a billion dollars! Has anyone ever seen such beauty? Ill be dreaming of this pic! Youre always blessing ...',
		date: '03 FEB 2023'
	},
]

