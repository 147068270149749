import React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardHeader, DashboardSidebar } from '../components/dashboard';

const DashboardLayout = () => {
	return (
		<div className="dashboard-container">
			<DashboardHeader />
			<DashboardSidebar />
			<Outlet/>
		</div>
	);
};

export default DashboardLayout;
