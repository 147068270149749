import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import { styles } from '../style';
import { Card, SearchNotFound } from '../components';
import { blogs } from '../constants';

const Search = () => {
	const params = useParams();
	const keyword = params.keyword;
	const keys = ['title', 'category'];
	const [searchedBlogs, setSearchedBlogs] = useState([]);


	useEffect(() => {
		//Call filter blogs method on every render
		setSearchedBlogs(getSearchedBlogs());

		//eslint-disable-next-line
	}, [keyword]);

	//Filter the blogs that match the query string
	const getSearchedBlogs = () => {
		return blogs.filter((blog) =>
			keys.some((key) =>
				blog[key].toLowerCase().includes(keyword.toLocaleLowerCase())
			)
		);
	};

	return (
		<div
			className={`search-result-container ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
		>
			{!searchedBlogs.length ? (
				<SearchNotFound keyword={keyword} />
			) : (
				<div className="search-result">
					<div className="search-result-heading category-blogs-heading">
						<div>
							Your search results for <span>'{keyword}'</span>
						</div>
						<div className="search-result-count">
							<span>({searchedBlogs.length})</span> results
						</div>
					</div>
					<div className="search-result-content">
						{searchedBlogs.map((blog) => (
							<Card key={blog.id} blog={blog} />
						))}
					</div>
				</div>
			)}
		</div>
	);
};
export default Search;