import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineChevronDown, HiChevronDoubleRight } from 'react-icons/hi2';
import { categories, blogs } from '../constants';
import { styles } from '../style';
import Thumbnail from './Thumbnail';

const MegaMenu = ({ resetMegaMenu }) => {
	const [currentCategory, setCurrentCategory] = useState('hospitality');

	const [currentSlug, setCurrentSlug] = useState('hospitality');

	const [activeBlogs, setActiveBlogs] = useState([]);

	const [isMediumScreen, setIsMediumScreen] = useState(false);

	useEffect(() => {
		if (resetMegaMenu) {
			setCurrentCategory('hospitality');
			setCurrentSlug('hospitality');
		}
	}, [resetMegaMenu]);

	useEffect(() => {
		//set initial value for medium screen
		const mediaWatcher = window.matchMedia('(max-width: 1024px)');

		setIsMediumScreen(mediaWatcher.matches);

		const updateIsMediumScreen = (e) => {
			setIsMediumScreen(e.matches);
		};

		//Add event listener for screen change
		mediaWatcher.addEventListener('change', updateIsMediumScreen);

		//Remove event listener for screen change
		mediaWatcher.removeEventListener('change', updateIsMediumScreen);
	}, []);

	useEffect(() => {
		filterBlogs(currentCategory);
		//eslint-disable-next-line
	}, [currentCategory, resetMegaMenu]);


	//filter blogs belongig to current (active) category
	const filterBlogs = (category) => {
		const filteredBlogs = blogs.filter((blog) => blog.category === category);

		//set the number of thumbnails to be displayed based on screen size.
		//4 thuumbnails for medium screen & 8 thumbnails for larger screen.
		if (isMediumScreen) {
			setActiveBlogs(filteredBlogs.slice(0, 4));
		} else {
			setActiveBlogs(filteredBlogs.slice(0, 8));
		}
	};

	return (
		<>
			<div className="navbar-mega-menu">
				<div className="navbar-mega-menu-topics">
					<div className="navbar-mega-menu-topics-heading">
						<div className="navbar-mega-menu-topics-tag">
							<div className="navbar-mega-menu-topics-tag-border">
								<span className="invisible">topics</span>
								<div className="navbar-mega-menu-topics-tag-text">topics</div>
							</div>
						</div>
					</div>
					<div className={`navbar-mega-menu-topics-list ${styles.heading4}`}>
						{categories.map((category) => (
							<li
								onClick={() => {
									setCurrentCategory(category.title);
									setCurrentSlug(category.slug);
									// setCurrentCategoryImg(category.image);
								}}
								key={category.id}
								className={
									category.title === currentCategory
										? 'navbar-mega-menu-topics-list-item navbar-mega-menu-topics-list-item-active'
										: 'navbar-mega-menu-topics-list-item'
								}
							>
								{category.title}
								<HiOutlineChevronDown
									className={`ml-1 ease-linear duration-300  ${
										category.title === currentCategory
											? '-rotate-90'
											: ' rotate-0'
									}`}
								/>{' '}
							</li>
						))}
					</div>
				</div>
				<div className="navbar-mega-menu-content">
					<div className="navbar-mega-menu-content-category-tag">
						<div className="navbar-mega-menu-content-category-tag-border">
							<span className="invisible">{currentCategory}</span>
							<div className="navbar-mega-menu-content-category-tag-text">
								{currentCategory}
							</div>
						</div>
					</div>
					<div className="navbar-mega-menu-content-link">
						<Link
							to={`/category/${currentSlug}`}
							className="category-blogs-link"
						>
							view more posts <HiChevronDoubleRight className="ml-1" />
						</Link>
					</div>
					<div className="navbar-mega-menu-content-blogs">
						{activeBlogs.map((blog) => (
							<Thumbnail key={blog.id} blog={blog} category={currentCategory}/>
						))}
					</div>
				</div>
			</div>
			<div className="navbar-mega-menu-sm">
				<div className="navbar-mega-menu-sm-topics-heading">
					<div className="navbar-mega-menu-sm-topics-tag">
						<div className="navbar-mega-menu-sm-topics-tag-border">
							<span className="invisible">topics</span>
							<div className="navbar-mega-menu-sm-topics-tag-text">topics</div>
						</div>
					</div>
				</div>
				<div className={`navbar-mega-menu-sm-topics-links ${styles.heading4}`}>
					{categories.map((category) => (
						<Link
							to={`/category/${category.slug}`}
							key={category.id}
							className="navbar-mega-menu-sm-topics-link"
						>
							{category.title}
						</Link>
					))}
				</div>
			</div>
		</>
	);
};

export default MegaMenu;
